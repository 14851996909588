import React from "react";
import "./App.css";
import { SiGithub, SiLinkedin, SiGmail } from "@icons-pack/react-simple-icons";
import TitleCarousel from "./components/TitleCarousel/TitleCarousel.tsx";

function App() {
  return (
    <div className="gradientBackground">
      <div className="contentContainer">
        <h1 className="nameAndSubtitle">Kristián Briškár</h1>
        <h2 className="nameAndSubtitle">Freelance developer of:</h2>
        <div className="titleContainer">
          <TitleCarousel />
        </div>
        <div className="iconsContainer">
          <a
            href="https://www.linkedin.com/in/kristian-briskar-sk-850834269/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiLinkedin color="rgba(27, 73, 89, 1)" size={54} />
          </a>
          <a
            href="https://github.com/BriskySK"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiGithub color="rgba(225, 185, 176, 1)" size={54} />
          </a>
          <a href="mailto:briskar.kristian+briskark.sk@gmail.com">
            <SiGmail color="rgba(149, 143, 145, 1)" size={54} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
