import React, { useState, useEffect } from "react";
import "./TitleCarousel.css";

const titles = [
  "Mobile apps",
  "Web apps",
  "Presentation websites",
  "Progressive web apps",
  "Software on demand",
];

const TitleCarousel: React.FC = () => {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
        setFadeIn(true);
      }, 2000);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="titleStyle">
      <div className={fadeIn ? "fadeIn" : "fade"}>
        {titles[currentTitleIndex]}
      </div>
    </div>
  );
};

export default TitleCarousel;
